<template>
  <div class='head'>
    <div class="logo"></div>
    <div class="menus">
      <el-menu class="tab_menu" :default-active="activeTab" text-color="#262626" active-text-color="#1777D9" background-color="transparent" mode="horizontal" @select="handleSelect">
        <el-menu-item class="tab_item" v-for="(item, index) in menuList" :key="index" :index="item.menuPath">{{ item.menuName }}</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      activeTab: "/",
      menuList: [
        { menuName: '首页', menuPath: '/' },
        { menuName: '信息公告', menuPath: '/notice' },
        { menuName: '公司简介', menuPath: '/desc' },
        { menuName: '招商加盟', menuPath: '/join' },
        { menuName: '联系我们', menuPath: '/contcat' },
      ],
    }
  },
  created () {
    this.activeTab = sessionStorage.getItem('activeTab') || '/';
  },
  methods: {
    // 切换菜单
    handleSelect (index, path) {
      this.activeTab = path.join();
      sessionStorage.setItem('activeTab', this.activeTab);
      this.$router.push({ path: path.join() });
    },
  },
}
</script>

<style scoped lang='scss'>
.head {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 1440px;
  max-width: 1920px;
  height: 100px;
  z-index: 100;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

  .logo {
    position: absolute;
    top: 20px;
    left: 80px;
    width: 137px;
    height: 60px;
    background: url('../assets/img/logo.png') no-repeat;
  }

  .menus {
    .el-menu.el-menu--horizontal {
      border: 0;
    }

    .el-menu--horizontal>.el-menu-item {
      font-size: 16px;

      &:hover {
        background-color: transparent !important;
      }
    }

    .el-menu--horizontal>.el-menu-item.is-active {
      border: none;
    }

    .is-active {
      color: #fff;
      font-weight: bold;
      background-color: transparent !important;
    }

    .is-active::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 6px;
      border-radius: 3px;
      background-color: $themes;
      transform: all 0.3s;
    }
  }
}
</style>
